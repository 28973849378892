import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AmdocsAppsyncClientService } from 'amdocs-core';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  private gqlEndpoint = 'https://flex-dev.misnp.amdocs.com/graphql';

  constructor(private http: HttpClient,
    private amdocsAppsyncClientService: AmdocsAppsyncClientService
  ) { }

  generateQRCodes(buildingCode: number = 0, countryCode: string = '', seatCode: string = '', floorCode: number = 0, createNewQRCodes: boolean = false, wingGroupCode: string = ''): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = {
      buildingCode,
      countryCode,
      floorCode,
      createNewQRCodes
    }

    const body = {
      query: `query generateQRCodes($buildingCode: Int!, $countryCode: String!,$floorCode: Int!,$createNewQRCodes: Boolean!) {\n
          generateQRCodes(buildingCode: $buildingCode, countryCode: $countryCode,floorCode:$floorCode,createNewQRCodes: $createNewQRCodes)
        \n {message}}`
    };

    return this.amdocsAppsyncClientService.call(body.query, 'generateQRCodes', params).pipe(
      mergeMap((res: any) => {
        console.log(res);
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    )
  }
}